import React from "react";

import './general.css';

import API from "../../utils/api";
import ImgUploadV2 from "../../utils/img-upload-v2";
import CropResize from "../../utils/crop-resize";
import Modal from "../../modal";

import TextareaAutosize from 'react-autosize-textarea';

class EditCourseCovers extends React.Component {
    constructor(props) {
        super();

        this.state = {
          showError: false,
          previewInput: null,
          coverInput: null,
          cropData: null,
          selectedType: null,
          file: null,
        }
    }

    upload = () => {
      const t = this;
      const {cropData, file, selectedType} = t.state;
      const {reloadData} = t.props;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('image_type', selectedType);
      formData.append('course_id', t.props.courseData.id);
      formData.append('crop', cropData);

      t.setState({loading: true});

      API.post('/whub/v3/media', formData)
          .then(function (r) {
              console.log(r)
              t.setState({loading: false, src: r.data.src});

              if (t.state.emitSrc) {
                t.props.getSrc(r.data.src);
              } else {
                reloadData();
              }
          })
          .catch(function (error) {
              t.setState({loading: false});
              console.log(error.response)
          });

    };

    handleFileLoad = (file) => {
      this.setState({file: file});
    };

    handlePreviewSelect = (base64) => {
      this.setState({previewInput: base64, coverInput: null, selectedType: 'preview'});
    };

    handleCoverSelect = (base64) => {
      this.setState({coverInput: base64, previewInput: null, selectedType: 'cover'});
    }

    handleCropChange = (name, value) => {
      const t = this;
      const obj = {};

      obj[name] = value;
      t.setState(obj, () => {
          if (name === 'cropData' && value) {
              t.upload();
          }
      });
    };

    handleFileTypeError = () => {
      this.setState({showError: true});
    }

    hideErrorModal = () => {
      this.setState({showError: false})
    }

    showModal = () => {
      const t = this;
      const s = t.state;
      const p = t.props;

      const data = {
        inputName: null,
        cropSize: {width: null, height: null},
        img: null
      }

      if (!s.previewInput && !s.coverInput) return '';

      if (s.previewInput) {
        data.inputName = 'previewInput';
        data.cropSize = {width: 608, height: 296};
        data.img = s.previewInput;
      }

      if (s.coverInput) {
        data.inputName = 'coverInput';
        data.cropSize = {width: 1440, height: 765};
        data.img = s.coverInput;
      }

      const crop = <CropResize {...p} onChange={t.handleCropChange} inputName={data.inputName} outputName="cropData" cropSize={data.cropSize} orientation={'portrait'} toCoords={true} isRound={false} hideOrientation={true} img={data.img} />;

      return <Modal modalContent={'custom'} customContent={crop} onModalClose={t.hideModal} />;
    }

    hideModal = () => {
      this.setState({previewInput: null, file: null, cropData: null, selectedType: null});
    };

    render() {
        const t = this;
        const p = t.props;
        const {showError} = t.state;

        const imgs = p.courseData.images_stack || {};
        const cls_preview_loaded = imgs.preview ? 'loaded' : '';
        const cls_cover_loaded = imgs.cover ? 'loaded' : '';

        const previewImg = imgs.preview ? <img src={imgs.preview.normal} alt="" /> : '';
        const coverImg = imgs.cover ? <img src={imgs.cover.normal} alt="" /> : '';

        const errorText = `Невозможно загрузить файл. Поддерживаемые форматы файлов для загрузки картинок: png, jpg, jpeg, webp, heif, heic`;
        const errorModal = showError ? <Modal modalContent={'text'} modalData={{text: errorText}} onModalClose={t.hideErrorModal} /> : '';

        return (
            <div className="course_edit_block ceb_general">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={p.courseData.title} name="courseName" placeholder="название курса" />
                </div>

                <div className="course_covers_block">
                    <div className="ce_grid">
                        <div className="ce_grid_block ce_left_block">
                            <div className="ce_upload_wrapper">
                                <h5>превью</h5>
                                <label htmlFor="previewUpload">
                                  <div className={cls_preview_loaded + ' ce_upload ce_upload_preview'}>
                                      <ImgUploadV2 onFail={t.handleFileTypeError} onChange={t.handlePreviewSelect} onFile={t.handleFileLoad} name="preview" id="previewUpload" accept="image/*" />
                                      {previewImg}
                                  </div>
                                </label>
                                <div className="course_form_hint">*.png или *.jpg в разрешении не менее 608px х 296px</div>
                            </div>

                        </div>
                        <div className="ce_grid_block ce_right_block">
                            <h5>обложка</h5>
                            <label htmlFor="coverUpload">
                              <div className={cls_cover_loaded + ' ce_upload ce_upload_cover'}>
                                  <ImgUploadV2 onFail={t.handleFileTypeError} onChange={t.handleCoverSelect} onFile={t.handleFileLoad} name="cover" id="coverUpload" accept="image/*" />
                                  {coverImg}
                              </div>
                            </label>
                            <div className="course_form_hint">*.png или *.jpg в разрешении не менее 1440px х 765px</div>
                        </div>
                    </div>
                </div>
                {t.showModal()}
                {errorModal}
            </div>
        );
    }
}

export default EditCourseCovers;