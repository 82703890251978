import React from "react";
import './index.css';

import Spinner from "../../utils/spinner";
import { Link } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import PageParamsHook from "../../utils/page-params-hook";
import API from "../../utils/api";
// import GeoPattern from "geopattern";
import {checkPostOwner} from "../../utils/utils";
import Error404 from "../../utils/404";

class BlogPost extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            data: {},
            pageParams: null,
            is404: false
        }
    }

    loadPost = (postId) => {
        const t = this;

        t.setState({loading: true});
        API.get('/whub/v3/post/' + postId)
            .then(function (r) {

                console.log(r)

                t.setState({
                    loading: false,
                    data: r.data
                });
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});

                if (error.response.status === 401) {
                    t.setState({is404: true});
                }
            });
    };

    onParams = (params) => {
        const t = this;

        t.setState({pageParams: params});

        t.loadPost(params.id)
    };

    render () {
        const t = this;
        const {loading, pageParams, data, is404} = t.state;
        const {userData} = t.props;

        const paramsHook = pageParams ? '' : <PageParamsHook onParams={t.onParams}/>;

        const title = data.title ? data.title : '';
        const content = data.content ? data.content : '';

        const isOwner = pageParams ? checkPostOwner(pageParams.id, userData) : false;
        const editButton = pageParams && isOwner ? <Link className="post_edit_button" to={'/blog/' + pageParams.id + '/edit'}><button className="v2 small edit_post">редактировать</button></Link> : '';

        const imagesStack = data.images_stack ? data.images_stack : null;
        const cover = imagesStack && imagesStack.cover ? data.images_stack.cover.normal : null;
        const image = cover ? <div className="post_image"><img src={cover} alt="" /></div> : '';


        // const pattern = GeoPattern.generate(title, {color: '#434A5C'});
        // const bgStyle = {backgroundImage: pattern.toDataUrl()};
        // const patternPlug = image ? '' : <div className="blog_post_pattern" style={bgStyle}>&nbsp;</div>;

        if (is404) {
            return <Error404 />
        }

        return (
            <div className="blog_post_wrapper">
                {/* {patternPlug} */}
                <div className="blog_post_inner">
                    {paramsHook}

                    {editButton}

                    {image}

                    <h1>{title}</h1>

                    <div className="blog_post_inner" dangerouslySetInnerHTML={{ __html: content }}>
                    </div>


                    <Spinner show={loading} />
                </div>
            </div>
        );
    }
}

export default BlogPost;