import React from 'react';

import './index.css';
import API from "../../utils/api";
import MainFeed from "./main-feed";
import CoursesFeatured from "../../course-snippets/featured/v3";
import BlogFeed from "../../course-snippets/blog";

import Spinner from "../../utils/spinner";
import CategoriesButtons from "../../course-snippets/category-buttons";

import MainSchools from "./schools";
import SchoolsInvitation from "./schools-invitation";
import MainHeader from "./header";
import MainSearchBar from "./search-bar";
import MainStatistics from "./statistics";
import MainPartners from "./partners";
import MainReviews from "./reviews";
import MainCerts from './certs/index.js';
import MainCatalog from "./catalog";

import collectionImage from '../../course-snippets/featured/v3/collection-family-orange.jpg';

class Main extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false
        }
    }

    loadMainObject = () => {
        const t = this;
        
        t.setState({loading: true});
        API.get('/whub/v3/main')
            .then(function (r) {
                t.props.setMainPageObj(r.data);
                t.attachCollections();

                if (r.duration && r.duration > 6000) {
                    console.log('long wait!: +' + r.duration + 'ms');
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => t.setState({loading: false}));
    };

    loadLatest = () => {
        const t = this;

        API.get('/whub/v3/latest')
            .then(function (r) {
                t.props.setMainPageObj(r.data, 'latest');
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    attachCollections = () => {
      const t = this;
      const p = t.props;
      const o = p.mainPageObj;

      o.featured.unshift({
        id: 'collection-family-1',
        title: 'Детям и родителям',
        img: collectionImage,
        url: 'collections/family',
        collection: true,
      })
    }


    generateWatchingNow = (arr) => {
        const numbers = [];
        const output = [];
        while (output.length < 10) {
            const rnd = Math.floor(Math.random() * 50);
            if (numbers.indexOf(rnd) === -1) {
                numbers.push(rnd);
                output.push(arr[rnd]);
            }
        }

        return output;
    };

    componentDidMount() {
        document.body.classList.add('main_page');
        if (!this.props.mainPageLoaded) {
            this.loadMainObject();
            this.loadLatest();
        }
    };
    componentWillUnmount() {
        document.body.classList.remove('main_page');
    };

    render() {
        const t = this;
        const p = t.props;
        const o = p.mainPageObj;
        const {latest} = t.props;
        const {loading} = t.state;


        const feedPopular = o.popular.video.length ? <MainFeed
            {...p}
            courses={o.popular}
            title={'Популярное'}
            tagLine="Лучшие образовательные курсы на W-HUB."
            linkText="Посмотреть все"
            linkUrl="/search?orderby=rating&max_price=100000&order=desc" /> : '';

        const feedLatest = latest.video && latest.video.length ? <MainFeed
            {...p}
            courses={latest}
            title={'Новое'}
            tagLine="Новинки онлайн обучения на W-HUB."
            linkText="Полный список"
            linkUrl="/search?orderby=date&max_price=100000&order=desc" /> : '';

        const feedDiscounts = o.discounts ? <MainFeed
            {...p}
            noTabs={true}
            courses={o.discounts}
            title="Акции и скидки"
            tagLine="Хотите больше скидок?"
            linkText="Посмотреть полный список"
            linkUrl="/search?withDiscount=true" /> : '';

        const feedFree = o.free_courses ? <MainFeed
            {...p}
            noTabs={true}
            courses={o.free_courses}
            title="Учитесь бесплатно"
            tagLine="Ищешь бесплатное обучение?"
            linkText="Большой выбор"
            linkUrl="/search?free=true&orderby=rating&max_price=100000" /> : '';


        const feedArticles = o.articles.length ?  <BlogFeed
            {...p}
            articles={o.articles}
            title={'Блог'}
            tagLine="Читать еще про образование?"
            linkText="Перейти к статьям"
            linkUrl="/articles" /> : '';


        const featuredBlock = o.featured.length ? <CoursesFeatured {...p} data={o.featured} /> : '';

        const feedCategories = o.top_topics ? <CategoriesButtons categories={o.top_topics} /> : '';
        const feedQuarantine = o.quarantine.video.length ? <MainFeed withBackground {...p} courses={o.quarantine} title={'Рекомендуем'} /> : '';
        const feedWatchingNow = o.watching_now ? <MainFeed {...p} courses={t.generateWatchingNow(o.watching_now)} title={'Сейчас смотрят'} noTabs={true} /> : '';

        const feedSchools = o.school && o.school.length ? <MainSchools data={o.school} /> : '';

        const reviews = o.reviews ? <MainReviews data={o.reviews} /> : '';
        const certs = o.certificates ? <MainCerts data={o.certificates} /> : '';
        const statistics = o.total ? <MainStatistics data={o} /> : '';

        const catalog = o.other_topics ? <MainCatalog data={o.other_topics} /> : '';

        const invite = !p.authorized ? <SchoolsInvitation /> : '';

        return (
            <div className="main_page">

                <MainHeader {...p} />

                {featuredBlock}

                <div className="main_content">
                    <div className="main_feed_wrapper feed_popular_categories main_centrer">
                        <h2>Топ-темы</h2>

                        {feedCategories}
                    </div>
                    {feedPopular}
                    {feedArticles}

                    <div className="dup_main_search main_centrer">
                        <MainSearchBar className="with_border" />
                    </div>

                    {feedLatest}

                    {feedSchools}

                    {feedDiscounts}

                    {feedWatchingNow}

                    {feedQuarantine}

                    {feedFree}

                    {catalog}

                    {reviews}

                    {certs}

                    {invite}

                    {statistics}

                    <MainPartners />

                </div>

                <Spinner show={loading} />
            </div>
        );
    }
}

export default Main;
