import React from 'react';
import API from '../../../utils/api';
import ProfileModal from '../../profile-forms/student-profile/modals/commonModal';
import UserpicUpload from '../../profile-forms/userpic-upload';

import './expertsModal.css';

class ExpertsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isEdit: props.author?.isEdit || false,
      author: props.author || null,
      id: props.author?.id || "",
      avatar: props.author?.avatar || "",
      name: props.author?.name || "",
      middle_name: props.author?.middle_name || "",
      surname: props.author?.surname|| "",
      profession: props.author?.profession || "",
      about: props.author?.about || "",
      email: props.author?.email || "",
      validateError: {
        name: false,
        about: false,
        surname: false
      },
      showHint: {
        email: false,
      }
    }
  }

  beforeClose = () => {
    this.clearState();
  }

  handleShowHint = (type, show) => {
    const {showHint} = this.state;
    showHint[type] = show;
    this.setState({showHint});
  }

  clearState = () => {
    this.setState({
      isEdit: false,
      id: "",
      avatar: "",
      name: "",
      middle_name: "",
      surname: "",
      profession: "",
      about: "",
      email: "",
      validateError: {
        name: false,
        about: false,
        surname: false
      }
    }, () => this.props.close())
  }

  validate = () => {
    const { name, surname, about, validateError} = this.state;

    if (name.length === 0 || surname.length === 0 || about.length === 0) {
      validateError.name = name.length === 0;
      validateError.surname = surname.length === 0;
      validateError.about = about.length === 0;
      this.setState({
        validateError
      })
      return false
    }


    return true;
  }

  handleSave = () => {
    const t = this;
    const {isEdit, id, avatar, name, middle_name, surname, profession, email, about} = t.state;

    const data = {
      avatar,
      name,
      middle_name,
      surname,
      profession,
      email,
      about
    }

    if (!this.validate()) {
      return;
    } else {
      this.props.loading(true);

      if (isEdit) {
        API.patch(`/whub/v3/author/${id}`, data)
          .then(function (r) {})
          .catch(function (error) {
              console.log(error.response);
          })
          .finally(() => {
            this.props.update()
            this.props.loading(false);
            this.props.close();
          });
      } else {
        API.post('/whub/v3/author', data)
            .then(function (r) {})
            .catch(function (error) {
                console.log(error.response);
            })
            .finally(() => {
              this.props.update()
              this.props.loading(false);
              this.props.close();
            });
      }
    }
  }

  render() {
    return (
      <div>
        <ProfileModal title="" width="700px" closeModal={this.beforeClose}>
          <div className="experts_modal">
            <div className="experts_modal_title">
              <span>Создание эксперта</span>
            </div>

            <div className="experts_modal_row">
              <div className="experts_modal_column">
                <div>
                  <div className="experts_modal_input_label">
                    <span>Фамилия</span>
                  </div>
                  <div className={`experts_modal_input ${this.state.validateError.surname ? "validate_error" : ""}`}>
                    <input value={this.state.surname} onChange={(e) => {this.setState({surname: e.target.value})}}/>
                  </div>
                </div>

                <div>
                  <div className="experts_modal_input_label">
                    <span>Имя</span>
                  </div>
                  <div className={`experts_modal_input ${this.state.validateError.name ? "validate_error" : ""}`}>
                    <input value={this.state.name} onChange={(e) => {this.setState({name: e.target.value})}}/>
                  </div>
                </div>

                <div>
                  <div className="experts_modal_input_label">
                    <span>Отчество (если есть)</span>
                  </div>
                  <div className="experts_modal_input">
                    <input value={this.state.middle_name} onChange={(e) => {this.setState({middle_name: e.target.value})}}/>
                  </div>
                </div>

                <div>
                  <div className="experts_modal_input_label">
                    <span>Профессия</span>
                  </div>
                  <div className="experts_modal_input">
                    <input maxLength={55} value={this.state.profession} onChange={(e) => {this.setState({profession: e.target.value})}}/>
                  </div>
                </div>
              </div>

              <div className="experts_modal_column">
                <div className="experts_modal_upload_wrapper">
                  <UserpicUpload src={this.state.avatar.length > 0 ? this.state.avatar : null} image_type={"author_avatar"} {...this.props} getSrc={(data) => {this.setState({avatar: data})}}/>
                </div>

                <div>
                  <div className="experts_modal_input_label">
                    <span>Об эксперте</span>
                  </div>
                  <div className={`experts_modal_input experts_modal_textarea ${this.state.validateError.about ? "validate_error" : ""}`}>
                    <textarea maxLength={600} rows="5" value={this.state.about} onChange={(e) => {this.setState({about: e.target.value})}}/>
                  </div>
                </div>

                <div style={{alignSelf: 'flex-start'}}>
                  <div className="experts_modal_input_label">
                    <span>Email</span>
                    <div
                      onMouseOver={() => this.handleShowHint('email', true)}
                      onMouseOut={() => this.handleShowHint('email', false)}
                      className="experts_modal_tip">?</div>
                    {
                      this.state.showHint.email ? <div className="experts_modal_tip_content">
                      Добавьте e-mail и в случае назначения эксперта на вебинар, ему будут приходить напоминания и приглашения
                    </div> : ""
                    }
                  </div>
                  <div className="experts_modal_input">
                    <input value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="experts_modal_button_block">
              <div onClick={this.beforeClose} className="experts_modal_button_block_decline">
                <span>Отменить</span>
              </div>
              <div onClick={this.handleSave} className="experts_modal_button_block_confirm">
                <span>Сохранить</span>
              </div>
            </div>


          </div>
        </ProfileModal>
      </div>
    )
  }
}

export default ExpertsModal;