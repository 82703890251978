import React from 'react';
import loadImage from 'blueimp-load-image';
import {checkMime} from '../utils';

class ImgUploadV2 extends React.Component {
    constructor(props) {
        super();

        this.input = React.createRef();
    }

    handleUpload = (e) => {
        const t = this;
        const {onChange, onFile, onFail} = t.props;
        const file = e.target.files[0];

        checkMime(file, (result) => {
          if (file && result) {
            if (onFile) {
                onFile(file);
            }

            loadImage(
                file, (canvas) => {
                    const base64 = canvas.toDataURL('image/jpeg');
                    onChange(base64);
                    t.input.current.value = null;
                },
                { maxWidth: 3000,
                    maxHeight: 3000,
                    canvas: true,
                    orientation: true}
            );
         } else {
          if (onFail) {
            onFail()
          } else {
            alert('Неподдерживаемый формат файла');
          }
         }
        })
    };

    render() {
        const t = this;
        const p = t.props;

        return <input ref={t.input} id={p.id} type="file" onChange={t.handleUpload} accept={p.accept} />;
    }
}

export default ImgUploadV2;