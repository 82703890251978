import React from 'react';
import LessonText from "./text";
import LessonPdf from "./pdf";
import LessonVideo from "./video";
import LessonWebinar from "./webinar";
import {arr2obj, html2plaintext} from "../../utils/utils";
import {crumbBuilder} from "../../utils/breadcrumbs/crumb-builder";
import Breadcrumbs from "../../utils/breadcrumbs";

import LeaveReview from "../../course-v3/landing/components/leave-review";

import './index.css';

class LessonContent extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;
        const {courseData, pageParams, lessonId, type, onToggleSidebar, isReview} = p;

        const lessons = courseData.lessons;
        const lessonsObj = arr2obj(lessons, 'id');
        const lessonData = lessonsObj[lessonId];

        const types = {
            text: <LessonText {...p} key={lessonId + 'text'}/>,
            pdf: <LessonPdf {...p} key={lessonId + 'pdf'}/>,
            video: <LessonVideo {...p} key={lessonId + 'video'}/>,
            webinar: <LessonWebinar {...p} key={lessonId + 'webinar'}/>,
            review: <LeaveReview {...p} isLessonsPage={true} courseId={courseData.id}/>
        };

        const crumbs = crumbBuilder('course', {data: courseData, pageParams: pageParams});

        const withDescription = type === 'video' || type === 'pdf';

        return (
            <div className="lesson_content">
                <div className="lesson_inner">
                    <div className="lesson_top">
                        <Breadcrumbs data={crumbs} />
                        <h1>{html2plaintext(isReview ? '' : lessonData.title)}</h1>
                        <button onClick={onToggleSidebar} className="v2 light_inverted sidebar_toggler">Содержание курса</button>
                    </div>
                    {types[type]}

                    { withDescription ? <div className="lesson_description">
                      <div className="lesson_description_title">
                        <span>Описание</span>
                      </div>
                      <div className="lesson_description_content">
                        {isReview ? '' : lessonData.description}
                      </div>
                    </div> : '' }
                </div>
            </div>
        );
    }
}

export default LessonContent;
