import React from 'react';
import Hammer from 'react-hammerjs';
import './index.css';

class Authors extends React.Component {
    constructor (props) {
        super();

        this.state = {
            current: 0,
            step: 400,
            showFullText: [],
        };
    }

    componentDidMount = () => {
        this.updateStep();
        const full = this.props.data.authors.map(author => {
          return {show: author.about.length > 300 ? false : true}
        });
        this.setState({showFullText: full})
    };

    updateStep = () => {
        const t = this;
        const wW = window.innerWidth;
        let step = 400;

        if (wW <= 700) {
            step = 310;
        }

        t.setState({step: step});
    };

    handleAuthorClick = (idx) => {
        const t = this;
        const wW = window.innerWidth;
        const {current} = t.state;

        if (wW < 700 && idx !== current) {
            t.slideTo(idx);
        }
    };

    handleShowFullText = (idx) => {
      const t = this;
      const fullText = t.state.showFullText;
      fullText[idx].show = true;

      t.setState({showFullText: fullText});
    }

    authorsMapper = (author, idx) => {
        if (author && author.name && author.about) {

            const avatar = author.avatar ? author.avatar : null;
            const showFullText = this.state.showFullText[idx] ? this.state.showFullText[idx].show : true;
            const about = showFullText ? author.about : author.about.substr(0, 300) + '...';
            const expandBtn = !showFullText ? 
              <div className="expand_author" onClick={() => this.handleShowFullText(idx)}>
                Развернуть 
                <div className="expand_author_dots">
                  <div className="expand_author_dot" />
                  <div className="expand_author_dot" />
                  <div className="expand_author_dot" />
                </div>
              </div> : '';
            return (
                <div key={idx} onClick={() => this.handleAuthorClick(idx)} className="feed_item">
                    <div className="feed_item_header">
                      <div style={avatar ? {backgroundImage: `url(${avatar})`} : {backgroundColor: '#040E26'}} className="userpic">
                          <span>{avatar ? null : author.name[0].toUpperCase()}</span>
                      </div>
                      <div className="feed_item_header_title">
                        <h5>
                            {author.name}
                            {' '}
                            {author.surname}
                        </h5>
                        <span className="feed_item_header_title_prof">{author.profession}</span>
                      </div>                           
                    </div>
                    <p className="description">
                      {about}
                      {expandBtn}
                    </p>
                </div>
            );
        }
        return '';
    };

    slide = (dir) => {
        const t = this;
        const p = t.props;
        const authors = p.data.authors;

        const {current} = t.state;

        const next = current + dir;

        if (next >= 0 && next < authors.length) {
            t.slideTo(next);
        }
    };

    slideTo = (idx) => {
        this.setState({current: idx});
    };

    render() {
        const t = this;
        const p = t.props;
        const authors = p.data.authors;
        const {current, step} = t.state;

        const isSingleAuthor = authors.length === 1;
        const singleAuthor = {
          name: authors[0].name,
          surname: authors[0].surname,
          profession: authors[0].profession,
          about: authors[0].about,
          avatar: authors[0].avatar,
        }

        const style = {marginLeft: current * -step};

        const arrLeft = current > 0 ? <div onClick={() => t.slide(-1)} className="arr arr_left"> </div> : '';

        const hasRightArr = current < authors.length-1 && authors.length > 3 && current < authors.length - 3;
        const arrRight = hasRightArr ? <div onClick={() => t.slide(1)} className="arr arr_right"> </div> : '';

        const hammerOptions = {
            touchAction:'compute',
            recognizers: {
                swipe: {
                    threshold: 10
                }
            }
        };

        return (
            <div className="cl_authors">
                <div className="cl_centrer">
                    <h2>Вас будут обучать эксперты</h2>

                  { !isSingleAuthor ?
                    <div className="authors_carousel">
                        <div ref={t.holder} className="feed_holder">
                            <Hammer
                                direction={'DIRECTION_HORIZONTAL'}
                                options={hammerOptions}
                                onSwipeLeft={() => this.slide(1)}
                                onSwipeRight={() => this.slide(-1)}>
                                <div className="feed" style={style}>
                                    {authors.map(t.authorsMapper)}
                                </div>
                            </Hammer>
                        </div>

                        {arrLeft}
                        {arrRight}
                    </div>  : 
                    
                    <div className="feed_item_single">
                      <div className="feed_item_single_header">
                        <div style={singleAuthor.avatar ? {backgroundImage: `url(${singleAuthor.avatar})`} : {backgroundColor: '#040E26'}} className="userpic">
                            <span>{singleAuthor.avatar ? null : singleAuthor.name[0].toUpperCase()}</span>
                        </div>
                        <div className="feed_item_single_header_title">
                          <h5>
                              {singleAuthor.name}
                              {' '}
                              {singleAuthor.surname}
                          </h5>
                          <span className="feed_item_single_header_title_prof">{singleAuthor.profession}</span>
                        </div>                           
                      </div>
                      <span>{singleAuthor.profession}</span>
                      <p className="description">
                          {singleAuthor.about}
                      </p>
                    </div>
                  }
                </div>
            </div>
        );
    }
}

export default Authors;
