import React, { useState, useRef } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import './index.css';
import {ArrowPrevWhite as ArrowPrev} from "../icons";

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const PdfViewer = ({url, onCount, sendStatus}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [docHeight, setDocHeight] = useState('auto');
    const [showControls, setShowControls] = useState(false);
    const docHolder = useRef();

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);

        if (onCount) {
            onCount(numPages);
        }

        if (numPages === pageNumber) {
          sendStatus();
        }

        setTimeout(() => {
            setShowControls(true);
            setDocHeight(docHolder.current.clientHeight);
        }, 1000)
    };

    const onClickNext = () => {
        const next = pageNumber + 1;
        if (next <= numPages) {
            setPageNumber(next);
        }

        if (next === numPages) {
          sendStatus();
        }
    };

    const onClickPrev = () => {
        if (pageNumber === 1) {
          return
        }
        const next = pageNumber - 1;
        if (next >= 0) {
            setPageNumber(next);
        }
    };

    const urlParser = (url) => {
        let output = url.replace('www.dropbox.com', 'dl.dropboxusercontent.com').replace('dl=0', '');

        if (url.indexOf('docs.google.com') > -1 && url.indexOf('/d/') > -1) {
            const chunks_0 = url.split('/d/');
            const chunks_1 = chunks_0[1].split('/');

            const postUrl = url.indexOf('presentation') > -1 ? '/export/pdf' : '/export?format=pdf';

            output = chunks_0[0] + '/d/' + chunks_1[0] + [postUrl];
        }

        return output;
    };

    const arrPrev = <div onClick={onClickPrev} className={'pci_btn pci_btn_prev'}><i><ArrowPrev /></i></div>
    const arrNext = <div onClick={onClickNext} className={'pci_btn pci_btn_next'}><i><ArrowPrev /></i></div>

    const controls = showControls ? <div className="pdf_controls">
        <div className="pdf_controls_item pci_btn_holder">
            {arrPrev}
        </div>

        <div className="pdf_controls_item pci_btn_holder">
            {arrNext}
        </div>

        <div className="pdf_controls_item pdf_controls_center">
            Страница {pageNumber} из {numPages}
        </div>

    </div> : '';

    const viewer = url && url.length ? <div className="pdf_player">
        <div ref={docHolder} style={{ height: docHeight }} className="pdf_document_holder">
            <Document file={urlParser(url)} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>
        </div>

        {controls}
    </div> : '';


    return (
        <div className="pdf_viewer">
            {viewer}
        </div>
    )
};

export default PdfViewer;