import React from 'react';
import moment from 'moment';
import './index.css';
import {PriceFormat, Plural} from "../../../../utils/utils";
import {Certificate, TypeText, TypeVideo, TypeWebinar} from "../../icons";

import {
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  VKShareButton,
} from 'react-share';

import FBLogo from './icons/FB-Logo.svg';
import VKLogo from './icons/VK-Logo.svg';
import WAPPLogo from './icons/WAPP-Logo.svg';
import TGLogo from './icons/TG-Logo.svg';

import REPLogo from './icons/report.svg';
import STARLogo from './icons/Star.svg';
import MGLogo from './icons/message.svg';

import {sortLessonsByType} from "../../duration-utils";
class Purchase extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    defineCourseType = (sorted, count) => {
      let type = 'text';

      const typesMap = {
          video: {
              icon: <TypeVideo />,
              title: 'Видеокурс'
          },
          text: {
              icon: <TypeText />,
              title: 'Обучающий курс'
          },
          webinar: {
              icon: <TypeWebinar />,
              title: 'Вебинар'
          },
          online: {
              icon: <TypeWebinar />,
              title: 'Онлайн курс'
          }
      };

      if (sorted.video && sorted.video.count > count/2) {
          type = 'video';
      }

      if (sorted.webinar) {
          type  = 'webinar';

          if (sorted.webinar.count > 1) {
              type = 'online'
          }
      }

      return typesMap[type];
    };

    typesMapper = (el, idx) => {
      const typesMap = {
          video: ['видеоурок', '', 'а', 'ов'],
          video_upload: ['видеоурок', '', 'а', 'ов'],
          webinar: ['вебинар', '', 'а', 'ов'],
          pdf: ['презентаци', 'я', 'и', 'й'],
          text: ['текстов', 'ый урок', 'ых урока', 'ых уроков'],
      };

      const backgroundColor = {
        video: '#2450FF',
        video_upload: '#2450FF',
        webinar: '#BE0B0B',
        pdf: '#040E26',
        text: '#6AB261'
      }

      const count = el.count;
      const type = el.type;
      const t = typesMap[type];

      return (
          <li key={idx}><span style={{backgroundColor: backgroundColor[type]}}>{count}</span>{t[0]}{Plural(count, t[1], t[2], t[3])}</li>
      )
    };

    render() {
        const t = this;
        const {data, userData, authorized, handleGift, handlePurchase, handleEntry, cancelEntry, handleFavourite, handleReport} = t.props;

        const hasDiscount = !!parseInt(data.discount_amount_percentage);
        const price = hasDiscount ? data.price : data.regular_price;
        const isFree = !price;

        const purchased = userData.purchased || [];
        const isPurchased = purchased.indexOf(data.id) > -1;
        const isFavourited = userData.favourites && userData.favourites.length > 0 && userData.favourites.indexOf(data.id) > -1;

        const subscriptions = userData.subscriptions || {};
        const entries = subscriptions.courses || [];
        const hasEntry = entries.indexOf(data.id) > -1;

        const discountText = hasDiscount ? <div className="discount_text">Со скидкой {data.discount_amount_percentage} %</div> : '';
        const actualPrice = isFree ? 'Бесплатно' : PriceFormat(parseInt(Math.ceil(price))) + ' ₽';
        const regularPriceBlock = hasDiscount ? <div className="regular_price_block">
            <p>Стоимость без скидки</p>
            <span>{PriceFormat(parseInt(Math.ceil(data.regular_price)))} ₽</span>
        </div> : '';

        const isSchool = authorized && userData.role === 'school';

        let purchaseText = '';

        if (isPurchased) {
            purchaseText = 'В моих курсах';
        }
        else {
            if (isFree) {
                purchaseText = 'В мои курсы'
            }
            else {
                purchaseText = 'Купить';
            }
        }

        const meta = data.meta ? data.meta : {};
        const onlineCourseStatus = meta.online_course_status || {};
        const dateNow = new Date();
        const sellsStartDate = onlineCourseStatus.sell_starts_date || 0;
        const sellsStarted = dateNow.getTime() >= sellsStartDate;

        const isOnlineCourse = meta.date_start && parseInt(meta.date_start) !== 0;
        const isAvailableForEntry = onlineCourseStatus.available_for_entry ? onlineCourseStatus.available_for_entry : false;
        const hasSellsStarts = isOnlineCourse && onlineCourseStatus.sell_starts_date && parseInt(onlineCourseStatus.sell_starts_date) > 0;

        const btnPurchase = !isSchool && !isPurchased && (!hasSellsStarts || sellsStarted) ? <button onClick={handlePurchase} className="v2">{purchaseText}</button> : '';
        const btnEntry = !isSchool && !isPurchased && !hasEntry && isAvailableForEntry && !sellsStarted && isFree ? <button onClick={handleEntry} className="v2">Записаться</button> : '';

        const btnCancelEntry = !isSchool && !isPurchased && isOnlineCourse && hasEntry ? <button onClick={cancelEntry} className="v2">Отписаться</button> : '';


        const uKassa = !isFree ? <div className="u_kassa"> </div> : '';
        const btnGift = !isFree && authorized ? <button onClick={handleGift} className="v2 light_inverted">Подарить</button> : '';

        const sortedLessons = sortLessonsByType(data.lessons);
        let courseType = t.defineCourseType(sortedLessons.sorted, data.lessons.length);

        if (data.meta && data.meta.online_course_status) {
          courseType = {
              icon: <TypeWebinar />,
              title: 'Онлайн курс'
          };
        }

        const sUrl = window.location.href;

        const startDate = data.meta.date_start ? moment(data.meta.date_start).format('DD MMMM') : null;

        const isFavouritedText = isFavourited ? 'В избранном' : 'В избранное'

        return (
            <div className="cl_purchase">
                <div className="cl_centrer">

                    <div className="purchase_block">
                        <div className="part">
                          <div className="part_header">
                            <div className="part_header_type">
                                {courseType.icon}
                              <span>{courseType.title}</span>
                            </div>
                            <span className="part_header_timestart">Старт: <span>{startDate ? startDate : 'В любое время'}</span> </span>
                          </div>
                          <div className="part_title">
                            <span>{data.title}</span>
                          </div>
                          <div className="labels_block">
                              <div style={data.certificate_available ? {} : {display: 'none'}} className="certificate">
                                  <Certificate />
                                  <span>Сертификат W-HUB по окончании курса</span>
                              </div>
                          </div>
                          <div style={data.certificate_available ? {} : {bottom: '20px'}} className="materials_block">
                            <span className="materials_block_title" >Материалы</span>
                            <div className="material_types">
                              <ul>
                                  {sortedLessons.sortedArr.map(t.typesMapper)}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="part right">
                          <h2>Стоимость курса</h2>
                            <div style={isFree ? {justifyContent: 'left'} : {}} className="price_container">
                              <div className="price_block">
                                  {discountText}
                                  <div className="actual_price">{actualPrice}</div>
                                  {regularPriceBlock}
                              </div>
                              {uKassa}
                            </div>
                            {/*<div className="additional_params">*/}
                                {/*<div className="date_and_slots">*/}
                                    {/*<p>Старт курса: <span>23 марта</span></p>*/}
                                    {/*<p>Осталось мест: <span>120</span></p>*/}
                                {/*</div>*/}

                                {/*<div className="age_restriction">16+</div>*/}
                            {/*</div>*/}
                            <div className="buttons_holder">
                                {btnPurchase}
                                {btnEntry}
                                {btnCancelEntry}
                                {btnGift}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cl_footer">

                  <div className="cl_footer_left">
                    <div onClick={handleFavourite} className="cl_footer_right_item">
                      <div style={{backgroundImage: `url(${STARLogo})`}} className="cl_footer_right_item_ico"></div>
                      <span className="cl_footer_right_item_text">{isFavouritedText}</span>
                    </div>

                    <div className="cl_footer_right_item">
                      <div style={{backgroundImage: `url(${MGLogo})`}} className="cl_footer_right_item_ico"></div>
                      <span className="cl_footer_right_item_text">Написать автору</span>
                    </div>

                    <div onClick={handleReport} className="cl_footer_right_item">
                      <div style={{backgroundImage: `url(${REPLogo})`}} className="cl_footer_right_item_ico"></div>
                      <span className="cl_footer_right_item_text">Пожаловаться</span>
                    </div>
                  </div>


                  <div className="cl_footer_right">
                    <span className="cl_footer_right_desc">Поделиться</span>
                    <VKShareButton style={{width: 'auto'}} url={sUrl}>
                      <div style={{backgroundImage: `url(${VKLogo})`}} className="cl_footer_left_ico"></div>
                    </VKShareButton>

                    <FacebookShareButton style={{width: 'auto'}} url={sUrl}>
                      <div style={{backgroundImage: `url(${FBLogo})`}} className="cl_footer_left_ico"></div>
                    </FacebookShareButton>

                    <WhatsappShareButton style={{width: 'auto'}} url={sUrl}>
                      <div style={{backgroundImage: `url(${WAPPLogo})`}} className="cl_footer_left_ico"></div>
                    </WhatsappShareButton>

                    <TelegramShareButton style={{width: 'auto'}} url={sUrl}>
                      <div style={{backgroundImage: `url(${TGLogo})`}} className="cl_footer_left_ico"></div>
                    </TelegramShareButton>
                  </div>
                </div>
            </div>
        );
    }
}

export default Purchase;
