import React from 'react';
import ReactPlayer from 'react-player'

import './index.css';
import {pushPlaybackProgress} from "../utils";
import Kinescope from "./kinescope";

class PlayerV2 extends React.Component {
    constructor (props) {
        super();
        this.player = React.createRef();

        this.state = {
            ready: false
        }
    }

    componentDidMount() {
        const {url, onCanNotPlay} = this.props;
        const exceptions = url.match(/kinescope.io/g);

        if (!ReactPlayer.canPlay(url) && onCanNotPlay && !exceptions) {
            onCanNotPlay();
        }
        else {
        }
    };
    componentWillUnmount() {
    };

    handlePlay = () => {
        const {onPlayStarted} = this.props;
        if (onPlayStarted) {
            onPlayStarted(true);
        }
        this.handleProgress();
    };

    handleProgress = () => {
        const p = this.props;
        const player = this.player.current;
        const time = player.getCurrentTime();

        if (time && p.courseId && p.lessonId && p.authorized) {
            pushPlaybackProgress(p.courseId, {file_index: p.lessonId, time_in_secs: Math.round(time)});
        }
    };

    handleReady = () => {
        const s = this.state;
        const p = this.props;
        const player = this.player.current;

        if (!s.ready) {
            this.setState({ready: true});
            if (p.lessonId && p.progress && parseInt(p.lessonId) === parseInt(p.progress.file_index)) {
                player.seekTo(p.progress.time_in_secs);
            }
            else {
                player.seekTo(0);
            }
        }
    };

    onDuration = () => {

    };

    render() {
        const t = this;
        const {url, onDuration = () => {}} = t.props;

        if (url.match(/kinescope.io/g)) {
            return <Kinescope {...t.props} />
        }

        return (
            <div className="player_v2_wrapper">
                <ReactPlayer
                    ref={this.player}
                    url={url}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    progressInterval={10000}
                    onProgress={t.handleProgress}
                    onReady={t.handleReady}
                    onPlay={t.handlePlay}
                    onDuration={onDuration}
                    controls
                    light
                    playing
                    id="VideoPlayer"
                    className="react-player"
                    width="100%"
                    height="100%"/>
            </div>
        );
    }
}

export default PlayerV2;