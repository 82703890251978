import React from 'react';
import API from "../../../../utils/api";
import Spinner from "../../../../utils/spinner";
import './index.css';

import ReactApexChart from 'react-apexcharts';
import ru from './ru';

class CourseStatistics extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            stats: null,

            startDate: new Date(),
            period: 'year',

            series: null,
            dateStart: null,
            selection: 'views'
        };


    }

    componentDidMount() {
        this.loadStats();

        const initStartDate = this.state.startDate;
        initStartDate.setMonth(initStartDate.getMonth() - 12);

        this.setState({startDate: initStartDate});
    };

    loadStats = () => {
        const t = this;
        const {data} = t.props;

        t.setState({loading: true});
        API.get('/whub/v3/stat/' + data.id)
            .then(function (r) {
                t.setState({loading: false, stats: r.data});
                if (r.data.length) {
                    t.processStats();
                }
                console.log(r)
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error.response)
            });

    };

    processStats = () => {
        const t = this;
        const {stats,selection} = t.state;

        let dataArray = [];
        stats.forEach((el) => {
            const date = new Date(el.date).getTime();
            const value = el[selection];
            dataArray.push([date, value]);
        });

        const sorted = dataArray.sort(function(a, b) {
          return b[0] - a[0]
        })

        t.setState({dateStart: new Date(stats[0].date).getTime(), series: [{data: sorted}]});

    };

    switchChart = (tar) => {
        const t = this;

        t.setState({loading: true, selection: tar}, t.processStats);
    };

    stopLoading = () => {
        const t = this;
        const {loading} = t.state;

        if (loading) {
            t.setState({loading: false});
        }
    };

    updateStartDate = (period) => {
      let updatedDate = new Date();

      if (this.state.period === period) {
        return
      }

      if (period === 'year') {
        updatedDate.setMonth(updatedDate.getMonth() - 12);
      }

      if (period ===  'month') {
        updatedDate.setMonth(updatedDate.getMonth() - 1);
      }

      if (period === 'half-year') {
        updatedDate.setMonth(updatedDate.getMonth() - 6);
      }

      if (period === 'full') {
        const courseStart = new Date(this.props.data.date_created.date);

        updatedDate = courseStart;
      }

      this.setState({startDate: updatedDate, period});
    }

    render() {
        const t = this;
        const {loading, startDate, series, selection} = t.state;

        const options = {
            chart: {
                locales: [ru],
                defaultLocale: 'ru',
                id: 'area-datetime',
                type: 'area',
                height: 350,
                toolbar: {
                  show: true,
                  tools: {
                    reset: true,
                  }
                },
                zoom: {
                  autoScaleYaxis: true
                },
                events: {
                    animationEnd: t.stopLoading
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
                style: 'hollow',
            },
            xaxis: {
                type: 'datetime',
                min: startDate.getTime(),
                max: new Date().getTime(),
                tickAmount: 6
            },
            yaxis: {
                min: 0,
                labels: {
                    formatter: function(val) {
                        return val.toFixed(0);
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            }
        };

        const chart = series ? <ReactApexChart options={options} series={this.state.series} type="area" height={350} /> : '';

        return (
            <div className="cl_statistics">
                <div className="cl_centrer">
                    <h2>Статистика</h2>

                    <p>Вы можете видеть статистику взаймодействий с курсом, так как являетесь его автором!</p>

                    <div className="stat_tabs">
                        <span className={selection === 'views' ? 'active' : ''} onClick={() => t.switchChart('views')}>Просмотры страницы</span>
                        <span className={selection === 'favorites' ? 'active' : ''} onClick={() => t.switchChart('favorites')}>В Избранном</span>
                        <span className={selection === 'purchases' ? 'active' : ''} onClick={() => t.switchChart('purchases')}>Покупки</span>
                    </div>

                    <div className="date_tabs">
                      <span onClick={() => this.updateStartDate('month')} className={this.state.period === 'month' ? 'selected_period' : ''}>Месяц</span>
                      <span onClick={() => this.updateStartDate('half-year')} className={this.state.period === 'half-year' ? 'selected_period' : ''}>Пол Года</span>
                      <span onClick={() => this.updateStartDate('year')} className={this.state.period === 'year' ? 'selected_period' : ''}>Год</span>
                      <span onClick={() => this.updateStartDate('full')} className={this.state.period === 'full' ? 'selected_period' : ''}>Все</span>
                    </div>

                    {chart}

                    <Spinner show={loading} />
                </div>
            </div>
        );
    }
}

export default CourseStatistics;
