import React, {useEffect, useState} from "react";
import {useParams, Redirect} from "react-router-dom";

import './index.css';
import API from "../../utils/api";
import {checkOwner} from "../../utils/utils";
import EditCourseGeneral from "./general";
// import EditCourseLessons from "./lessons";
import EditCourseFinish from "./finish";
import EditCoursePrice from "./price";
import EditCourseTabs from "./tabs";
import EditCourseAdvert from "./advert";
import EditCourseCovers from "./covers";
import EditCourseLanding from "./landing";
import EditCourseLessonsV2 from "./lessons-v2";
import EditCourseDescriptions from "./descriptions";
import Spinner from "../../utils/spinner";
import Modal from "../../modal";

import linkicon from './icons/link.svg';
import menuIcon from './icons/menu.svg';
import crossIcon from './icons/cross.svg';

function EditCourse(props) {
    let pageParams = useParams();

    const role = props.userData.role;
    const isAdmin = role === 'administrator';

    const redirectNoAuth = !props.authorized || role !== 'school';
    const [redirectAuthFailed, setRedirectAuthFailed] = useState(false);
    const [redirectCoursePage, setRedirectCoursePage] = useState(false);
    const [redirectCoursesPage, setRedirectCoursesPage] = useState(false);
    const [redirectDashboard, setRedirectDashboard] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const [tabsStatus, setTabsStatus] = useState(0);
    const [showCloseModal, setShowCloseModal] = useState(false);

    const [linkTooltipVisible, setLinkTooltipVisible] = useState(false);
    const [menuTooltipVisible, setMenuTooltipVisible] = useState(false);
    const [closeTooltipVisible, setCloseTooltipVisible] = useState(false);

    const [dataIsChanged, setDataIsChanged] = useState(false);
    const [updatedDataObj, setUpdatedDataObj] = useState(null);

    const minPrice = 100;

    const blankCourseName = 'blank_course';

    //const [status, setStatus] = useState('');

    const courseId = pageParams.id;

    const loadData = () => {
        setLoaded(true);
        setLoading(true);

        API.get('/whub/v3/course/' + courseId + '?skip_cache=1')
            .then(function (r) {

                if (r.data.title === blankCourseName) {
                    r.data.title = '';
                }

                if (!r.data.lessons) {
                    r.data.lessons = [];

                }

                if (typeof r.data.lessons === "string") {
                    r.data.lessons = [];
                }

                if (!r.data.total_sales) {
                    r.data.total_sales = 0;
                }

                setLoading(false);
                setData(r.data);
                if (!checkOwner(r.data.id, props.userData) && !r.data.status === 'private' && props.userData.role !== 'administrator') {
                    setRedirectAuthFailed(true);
                }
                checkProgress(r.data);
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error)
            });
    };

    const updateData = (d, autoSave) => {
        const updated = Object.assign(data, d);

        setData(updated);
        checkProgress(updated);

        if (autoSave) {
            API.put('/whub/v3/course/' + courseId, autoSave)
                .then(function (r) {
                    console.log(r);

                })
                .catch(function (error) {
                    alert(error)
                });
        }
    };

    const checkProgress = (d) => {
        let updated = data;
        if (d) {
            updated = d;
        }

        const tabLessonsActive = updated.title.length;
        const tabPriceActive = updated.lessons[0] && !updated.lessons[0].blank && updated.description.length && updated.short_description.length;
        const isOnlineCourse = updated.meta && updated.meta.date_start && parseInt(updated.meta.date_start) !== 0 && updated.description.length && updated.short_description.length;

        let progress = 0;
        if (tabLessonsActive) {
            progress = 1;
        }

        if (tabPriceActive || isOnlineCourse) {
            progress = 2;
        }
        setTabsStatus(progress);
    };

    const goCoursePage = () => {
      setRedirectCoursePage(true);
    }

    const goDashboard = () => {
      setRedirectDashboard(true);
    }

    const goCoursesPage = () => {
      setRedirectCoursesPage(true);
    }

    const closeAction = () => {
      console.log(dataIsChanged);

      if (dataIsChanged) {
        setShowCloseModal(true);
      } else {
        setRedirectCoursesPage(true);
      }
    }

    const dataChanged = (status, data) => {
      setDataIsChanged(status);
      setUpdatedDataObj(data);
    }

    const showTooltip = (type, state) => {
      if (type === 'link') {
        setLinkTooltipVisible(state);
      }

      if (type === 'menu') {
        setMenuTooltipVisible(state);
      }

      if (type === 'close') {
        setCloseTooltipVisible(state);
      }
    }
    
    const saveChanges = () => {
      hideModal('close');

      if (updatedDataObj) {
        setLoading(true);
        API.put('/whub/v3/course/' + courseId, updatedDataObj)
                .then(function (r) {
                    console.log(r);
                    setRedirectCoursesPage(true);
                })
                .catch(function (error) {
                    alert(error)
                })
                .finally(function () {
                  setLoading(false)
                })
      }

    }

    const hideModal = (name) => {
      if (name === 'close') {
        setShowCloseModal(false)
      }
    }

    useEffect(() => {
      setDataIsChanged(dataIsChanged)
    }, [dataIsChanged]);

    useEffect(() => {
      setUpdatedDataObj(updatedDataObj)
    }, [updatedDataObj]);

    useEffect(() => {
        document.body.classList.add('no_search');
        if (!loaded) {
            loadData();
        }

        return () => {
            document.body.classList.remove('no_search');
        }
    });

    useEffect(() => {
      setDataIsChanged(false);
    }, [tabsStatus])

    let editorTab;
    switch (pageParams.tab) {
        case 'general':
            editorTab = <EditCourseGeneral {...props} updateData={updateData} courseData={data} dataChanged={dataChanged}/>;
            break;
        case 'covers':
            editorTab = <EditCourseCovers {...props} updateData={updateData} reloadData={loadData} courseData={data}/>;
            break;
        case 'descriptions':
            editorTab = <EditCourseDescriptions {...props} updateData={updateData} reloadData={loadData} courseData={data} dataChanged={dataChanged}/>;
            break;
        case 'landing':
            editorTab = <EditCourseLanding {...props} updateData={updateData} reloadData={loadData} courseData={data} />;
            break;
        case 'lessons':
            editorTab = <EditCourseLessonsV2 {...props} updateData={updateData} courseData={data} />;
            break;
        case 'price':
            editorTab = <EditCoursePrice {...props} updateData={updateData} courseData={data} minPrice={minPrice} />;
            break;
        case 'advert':
            editorTab = <EditCourseAdvert {...props} updateData={updateData} courseData={data} />;
            break;
        case 'finish':
            editorTab = <EditCourseFinish {...props} updateData={updateData} courseData={data} />;
            break;
        default:
            editorTab = '';
    }

    const renderEditBlock = data ? editorTab : '';

    const tabs = data ? <EditCourseTabs {...props} tabsStatus={tabsStatus} courseData={data} /> : '';

    const courseLink = data && data.slug ? `/course/${data.slug}` : null;
    const dashboardLink = data && data.lessons.length >= 1 && data.lessons[0].id ? `/course/${data.slug}/${data.lessons[0].id}` : null;

    const redirectToMain = redirectNoAuth && !isAdmin ? <Redirect to={{pathname: '/'}}/> : '';
    const redirectToProfile = redirectAuthFailed && !isAdmin ? <Redirect to={{pathname: '/user/profile'}}/> : '';
    const redirectToCoursePage = courseLink && redirectCoursePage ? <Redirect to={{pathname: courseLink}} /> : '';
    const redirectToDashboard = dashboardLink && redirectDashboard ? <Redirect to={{pathname: dashboardLink}} /> : '';
    const redirectToCourses = redirectCoursesPage ? <Redirect to={{pathname: '/user/courses'}} /> : '';

    const closeModalContent = <div className="close_modal">
      <span>Сохранить изменения?</span>
      <div className="close_modal_buttons_block">
        <button onClick={() => saveChanges()}>Да</button>
        <button onClick={() => goCoursesPage()}>Нет</button>
      </div>
    </div>

    const closeModal = showCloseModal ? <Modal modalContent={'custom'} customContent={closeModalContent} onModalClose={() => hideModal('close')}/> : '';

    return (
        <div className="edit_create_wrapper">
            <div className="cc_top">
                <h2>конструктор</h2>
                {/*<h6>{status}</h6>*/}

                {tabs}
            </div>

            <div className="edit_controls">
              <div 
                onMouseOver={() => showTooltip('link', true)}
                onMouseOut={() => showTooltip('link', false)}
                onClick={goCoursePage}
                style={{backgroundImage: `url(${linkicon})`}}
                className={`edit_controls_btn ${courseLink ? '' : 'inactive'}`}>
                { linkTooltipVisible ? <div className="edit_controls_tooltip">
                  <span>открыть страницу курса</span>
                </div> : '' }
              </div>

              <div
                onClick={goDashboard}
                onMouseOver={() => showTooltip('menu', true)}
                onMouseOut={() => showTooltip('menu', false)}
                style={{backgroundImage: `url(${menuIcon})`}}
                className={`edit_controls_btn ${dashboardLink ? '' : 'inactive'}`}>
                 { menuTooltipVisible ? <div className="edit_controls_tooltip">
                  <span>открыть страницу обучения</span>
                </div> : '' }
              </div>
              <div
                onClick={closeAction}
                style={{backgroundImage: `url(${crossIcon})`}}
                onMouseOver={() => showTooltip('close', true)}
                onMouseOut={() => showTooltip('close', false)}
                className="edit_controls_btn">
                { closeTooltipVisible ? <div className="edit_controls_tooltip">
                  <span>закрыть</span>
                </div> : '' }
              </div>
            </div>

            {renderEditBlock}

            <Spinner show={loading} />

            {closeModal}

            {redirectToMain}
            {redirectToProfile}
            {redirectToCoursePage}
            {redirectToDashboard}
            {redirectToCourses}
        </div>
    );
}

export default EditCourse;