import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import img from './404.png';
import './index.css';

class Error404 extends React.Component {
    constructor (props) {
        super();
        this.state = {
            status: ''
        }
    }

    componentDidMount() {
        document.body.classList.add('empty');
    };
    componentWillUnmount() {
        document.body.classList.remove('empty');
    };

    render() {
        const t = this;
        const {text} = t.props;

        const message = text ? text : 'к сожалению, мы не нашли то, что вы искали.';

        return (
            <div className="error_404_wrapper">
                <Helmet>
                  <meta name="prerender-status-code" content="404"/>
                </Helmet>
                <img src={img} alt="" />
                <h1><span>ошибочка-с вышла<ins>404</ins></span></h1>
                <p>{message}<br />
                    попробуйте <Link to={'/'}>вернуться назад</Link> или <Link to={'/'}>на главную</Link></p>
            </div>
        );
    }
}

export default Error404;